@niq-light-green: #bdffbb;
@niq-dark-green: #00a346;
@niq-flash-green: #00f000;
@niq-blue-deep: #060A45;
@niq-blue-light: #31D1FF;
@niq-gray-95: #0d0d0d;
@niq-gray-90: #1a1a1a;
@niq-gray-80: #333333;
@niq-gray-60: #666666;
@niq-gray-50: #808080;
@niq-gray-30: #b3b3b3;
@niq-gray-15: #d9d9d9;
@niq-gray-5: #f2f2f2;
@niq-red: #ff0000;
@niq-blue: #2D6DF6;
@niq-orange: #f06400;
@niq-yellow-07: #dbc400;
@niq-yellow-08: #e7d31a;
@niq-yellow-09: #ece700;

.niq-light-green {
  color: @niq-light-green !important;
}

.niq-dark-green {
  color: @niq-dark-green !important;
}

.niq-flash-green {
  color: @niq-flash-green !important;
}

.niq-gray-95 {
  color: @niq-gray-95 !important;
}

.niq-gray-90 {
  color: @niq-gray-90 !important;
}

.niq-gray-80 {
  color: @niq-gray-80 !important;
}

.niq-gray-60 {
  color: @niq-gray-60 !important;
}

.niq-gray-50 {
  color: @niq-gray-50 !important;
}

.niq-gray-30 {
  color: @niq-gray-30 !important;
}

.niq-gray-15 {
  color: @niq-gray-15 !important;
}

.niq-gray-5 {
  color: @niq-gray-5 !important;
}

.niq-red {
  color: @niq-red !important;
}

.niq-blue {
  color: @niq-blue !important;
}

.niq-blue-deep {
  color: @niq-blue-deep !important;
}

.niq-blue-light {
  color: @niq-blue-light !important;
}

.niq-orange {
  color: @niq-orange !important;
}

.niq-yellow-07 {
  color: @niq-yellow-07 !important;
}

.niq-yellow-08 {
  color: @niq-yellow-08 !important;
}

.niq-yellow-09 {
  color: @niq-yellow-09 !important;
}