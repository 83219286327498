@media only screen and (max-width: 3000px) {

  html,
  body {
    font-size: 18px;
    font-family: Arial;
  }
}

@media only screen and (max-width: 2600px) {

  html,
  body {
    font-size: 16px;
    font-family: Arial;
  }
}

@media only screen and (max-width: 2000px) {

  html,
  body {
    font-size: 12px;
    font-family: Arial;
  }
}


.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

// Height
.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.txt-r {
  text-align: right;
}

.txt-l {
  text-align: left;
}

.p-0 {
  padding: 0px;
}

.m-0 {
  margin: 0px;
}

each(range(24), {

  // Width
  .w-@{value} {
    width: (@value * 1rem);
  }

  // -- Padding
  .pt-@{value} {
    padding-top: (@value * 0.25rem);
  }

  .pb-@{value} {
    padding-bottom: (@value * 0.25rem);
  }

  .pl-@{value} {
    padding-left: (@value * 0.25rem);
  }

  .pr-@{value} {
    padding-right: (@value * 0.25rem);
  }

  .px-@{value} {
    padding-left: (@value * 0.25rem);
    padding-right: (@value * 0.25rem);
  }

  .py-@{value} {
    padding-top: (@value * 0.25rem);
    padding-bottom: (@value * 0.25rem);
  }

  .pl-rem-@{value} {
    padding-left: (@value * 1rem);
  }

  // -- Margin
  .mt-@{value} {
    margin-top: (@value * 0.25rem);
  }

  .mb-@{value} {
    margin-bottom: (@value * 0.25rem);
  }

  .ml-@{value} {
    margin-left: (@value * 0.25rem);
  }

  .mr-@{value} {
    margin-right: (@value * 0.25rem);
  }

  .mx-@{value} {
    margin-left: (@value * 0.25rem);
    margin-right: (@value * 0.25rem);
  }

  .my-@{value} {
    margin-top: (@value * 0.25rem);
    margin-bottom: (@value * 0.25rem);
  }

  // -- Line Height
  .leading-@{value} {
    line-height: (@value * 1rem);
  }
});

// -- Display
@selectors: none, block, inline-block, inline, flex;

each(@selectors, {
  .@{value} {
    display: @value;
  }
});

.nowrap {
  flex-wrap: nowrap;
}

.pointer {
  cursor: pointer;
}

.w9-half {
  width: calc(100vw / 2 - 10rem);
}

.w9-rest {
  width: calc(100vw - 10rem);
}

.abs-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.hidden {
  visibility: hidden;
}