@import "./niq-colors.less";
@import "./base.less";
@import "./custom-antd.less";

@font-size-base: 12px;

.ant-layout.ant-layout-fixed-header {
  position: relative;
  min-height: 100%;
}

.hover-button,
.hover-button::after {
  transition: all 0.5s;
}

.hover-button {
  overflow: hidden;
  position: relative;
  z-index: 1;
  text-transform: uppercase;
}

.hover-button:hover {
  color: #fff;
}

.hover-button::before,
.hover-button::after {
  background: @niq-blue-deep;
  content: "";
  position: absolute;
  z-index: -2;
  border-radius: 3px;
}

.hover-button::after {
  height: 100%;
  left: -130%;
  top: 0;
  transform: skew(50deg);
  transition-duration: 0.5s;
  transform-origin: top left;
  width: 0;
}

.hover-button:hover:after {
  height: 100%;
  width: 226%;
}

.hover-link {
  position: relative;
  padding: 8px 0;
  margin: 0 8px;
  font-size: 16px;
  color: #000;
  line-height: 1;
  transition: 0.2s all linear;
  display: inline-block;
  width: auto;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    width: 0;
    height: 100%;
    border-bottom: 2px solid @niq-flash-green;
    transition: 0.2s all linear;
  }
}

.hover-link:hover::before {
  width: 100%;
  top: 0;
  left: 0;
  transition-delay: 0.1s;
  border-bottom-color: @niq-flash-green;
}

.hover-link:hover~.hover-link::before {
  left: 0;
}


.nav-menu-list {
  height: calc(~"100% - 50px");
  background: @niq-blue-deep !important;
  color: #fff;
  border-right: none;
  overflow: hidden;
  overflow-y: auto;
  word-wrap: break-word;

  ::-webkit-scrollbar-thumb {
    border-radius: 15px;
    -webkit-box-shadow: inset 0 0 6px #a5a2a2;
    background: #77777780;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: @niq-blue-deep;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
    -webkit-box-shadow: inset 0 0 6px #a5a2a2;
    background: #77777780;
  }

  .ant-menu-item a,
  .ant-menu-item div,
  .ant-menu-submenu-arrow {
    color: #fff;
  }

  .ant-menu-sub.ant-menu-inline {
    background: @niq-blue-deep !important;
    color: #fff;
  }

  .ant-menu-item-selected {
    border-color: @niq-blue !important;
    background-color: @niq-blue-deep !important;
    border-left-style: solid;
    font-weight: 600;
    border-width: 7px;
    color: #fff !important;

    a {
      color: @niq-blue-deep !important;
    }

    &::after {
      display: none;
    }
  }

  .ant-menu-submenu-selected {
    color: #fff !important;
  }

  .ant-menu-submenu:hover {
    color: @niq-blue !important;

    .ant-menu-submenu-title>.ant-menu-submenu-arrow {
      color: #fff !important;
    }

  }

  .ant-menu-submenu-title:hover {
    color: @niq-blue !important;
  }

  .ant-menu-submenu-active {

    .ant-menu-submenu-arrow,
    .ng-star-inserted {
      color: #fff !important;
    }
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    padding-left: 14px !important;
  }

  .ant-menu-item-active,
  .ant-menu-item:hover,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    color: @niq-blue;
  }

  .nav-sub-menu-list {
    .ant-menu-item {
      padding-left: 28px !important;
      white-space: normal;
      word-wrap: break-word;
      background-color: #0b1169 !important;
      line-height: 20px;
    }
  }
}

.header-item {
  height: 4.1rem;
  cursor: pointer;
}