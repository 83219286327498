@light-primary-color: @niq-flash-green;

.ant-btn,
.ant-btn-primary .ant-input-search-button {
  background-color: @niq-blue;
  border-color: @niq-blue;
  color: #fff;

  &:hover {
    color: #fff;
    background-color: @niq-blue-deep;
    border-color: @niq-blue-deep;
  }
}

.ant-btn-background-ghost.ant-btn-primary {
  border-color: @niq-blue !important;
  background-color: rgb(217 217 217 / 0.3) !important;
  color: #fff;
  text-shadow: none;

  &:hover {
    color: @niq-blue-deep;
  }
}

.ant-btn-link {
  background-color: transparent;
  border-color: transparent;
  color: #000;
  font-size: 16px;

  &:focus,
  &:hover {
    background-color: transparent;
    border-color: transparent;
    color: #000;
  }
}

.ant-card {
  .ant-table .ant-table-tbody {
    tr:nth-child(odd) {
      background-color: @niq-gray-5;
    }

    >tr.ant-table-placeholder {
      background-color: #fff;

      &:hover>td {
        background-color: #fff;
      }
    }
  }

  .ant-table-thead>tr>th {
    border-bottom-color: @niq-gray-15;
  }
}

.ant-card-actions {

  .ant-btn,
  a.ant-btn.ant-btn-link {
    &:hover {
      color: @niq-dark-green;
    }
  }

  >li>span,
  >li>span>a {
    &:hover {
      color: @niq-dark-green;

      >.anticon,
      span {
        color: @niq-dark-green;
      }
    }

    >.anticon {
      &:hover {
        color: @niq-dark-green;
      }
    }
  }
}

.ant-layout-fixed-header .ant-pagination-item a,
.ant-layout-fixed-header .ant-pagination-item-ellipsis,
.ant-layout-fixed-header .ant-pagination,
.ant-layout-fixed-header .ant-pagination-item-link {
  color: #000 !important;
}

.ant-card-head .ant-tabs,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-card-head {
  color: #000;
}

.ant-form-item-label>label,
.ant-radio-wrapper {
  color: #000;
}

.ant-radio-button-wrapper:hover {
  background-color: @niq-gray-5;
  border-color: @niq-gray-15;
}

.ant-radio-button-wrapper-disabled:hover {
  color: @niq-gray-50;
  background: transparent;
}

.ant-drawer-content {
  background-color: #000;
  color: #fff;

  .ant-btn {
    color: #000;
    background-color: #fff;

    &:hover {
      background-color: @light-primary-color;
    }

    &.ant-btn-link {
      color: #fff;
      background-color: transparent;
      border: none;

      &:hover {
        background-color: transparent;
        color: @niq-dark-green;
      }
    }
  }

  .ant-drawer-close {
    color: rgba(255, 255, 255, 0.45);

    &:focus,
    &:hover {
      color: rgba(255, 255, 255, 0.75);
    }
  }

  .ant-drawer-title {
    color: #fff;
  }

  .ant-drawer-header {
    background-color: #000;
    color: #fff;
  }

  .ant-drawer-body {
    .ant-form {
      color: #fff;
    }

    .ant-form-item-label>label {
      color: #fff;
    }

    .ant-collapse {
      background-color: #000;

      >.ant-collapse-item>.ant-collapse-header {
        color: #fff;
      }
    }

    .ant-checkbox-wrapper {
      color: #fff;
    }

    .ant-checkbox-inner {
      background-color: #000;
      border: 1px solid #d9d9d9;
      border-radius: 0;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #000;
      border-color: #fff;
    }

    .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: #3c3c3c;
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner {
      background-color: #000;
      border-color: #fff;

      &:after {
        background-color: #fff;
      }
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-checked:hover .ant-checkbox-inner,
    .ant-checkbox-checked::after,
    .ant-checkbox-input:focus+.ant-checkbox-inner {
      border-color: #fff;
    }
  }

  .starIcon,
  .active-starIcon {
    color: #000;
  }

  .ant-row,
  .ant-col {
    color: #000 !important;
  }

  .ant-drawer-footer {
    color: #fff;
  }
}

.ant-btn.action-menus-btn {
  background-color: transparent;
  border: none;
  color: #000;

  &:hover {
    background-color: #d9d9d9;
    border: none;
    color: #000;
  }

  &:focus,
  &:active {
    background-color: transparent;
    color: #000;

    &:hover {
      background-color: #d9d9d9;
    }
  }
}

.ant-notification-notice-icon .anticon-exclamation-circle,
.anticon-question-circle {
  color: @niq-orange;
}

.anticon-warning {
  color: @niq-orange;
}

.ant-tag.niq-tpl-tag {
  border-color: black;
}

.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #232121 !important;
  border-color: @niq-blue !important;
  background-color: #fff !important;
  border-left-style: solid;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #fff;
  color: @niq-blue;
}

.ant-select-item {
  cursor: pointer;
  transition: background .3s ease;
}

.ant-select-tree {
  margin: 0;
  padding: 0;
  background: #232121;
  color: #fff;
  border-radius: 2px;
}

.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #232121;
  color: #00f000
}

.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
  color: #807c7c;
  cursor: not-allowed;
}

//默认弹出框样式
.modal-content {
  .ant-modal {
    background: #2D6DF6
  }

  .ant-modal-header {
    padding: 16px 24px;
    color: #fff !important;
    background: transparent;
    border-bottom: 0px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
    height: 55px
  }

  .ant-modal-body:before {
    content: '';
    position: absolute;
    left: 2.5%;
    top: 53px;
    bottom: auto;
    right: auto;
    height: 3px;
    width: 95%;
    background-color: @niq-blue-deep;
  }

  .ant-modal-content {
    position: relative;
    color: #fff;
    background: #2D6DF6;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    pointer-events: auto;
  }

  .ant-modal-title {
    margin: 0;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    word-wrap: break-word;
  }

  .ant-modal-close {
    color: #fff;
  }

  .ant-form-item-label>label,
  .ant-radio-wrapper {
    color: #fff;
  }

  .default {
    background-color: #fff;
    color: black;
  }

  .ant-btn-primary {
    color: #fff;
    border-color: @niq-blue-deep;
    background: @niq-blue-deep;
  }

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:hover {
    color: #f2f0f0;
    border-color: #807c7c;
    background: #807c7c;
    text-shadow: none;
    box-shadow: none;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 13px;
    background-color: #7e7e7e;
  }

  ::-webkit-scrollbar-thumb {
    background-color: @niq-blue-deep;
  }

  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 10px;
  }

  .ant-spin-dot-item {
    background-color: #e4eaf0;
  }

  .ant-spin {
    color: #e4eaf0;
  }
}

//白底黑字下拉框
.white-dropdown {
  .ant-select-dropdown {
    margin: 0;
    background-color: #fff !important;
    color: #232121 !important;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum", ;
    z-index: 1050;
    box-sizing: border-box;
    padding: 4px 0;
    overflow: hidden;
    // font-size: 14px;
    font-variant: initial;
    border-radius: 2px;
    outline: 0;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #232121 !important;
    font-weight: 600;
    border-color: @niq-blue !important;
    background-color: #fff !important;
    border-left-style: solid;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #fff;
    color: @niq-blue;
  }

  .ant-select-item {
    color: #232121;
    cursor: pointer;
    transition: background .3s ease;
  }

  .ant-select-item-option-disabled {
    color: #00000040 !important;
    cursor: not-allowed;
  }
}

.black-background-table {
  --page-a: #f5f5f5;

  .ant-table-body {
    overflow-y: auto !important;
  }

  .black-background-table .ant-empty-normal {
    color: @niq-blue-deep;
  }

  .ant-table table {
    width: 100%;
    text-align: left;
    border-radius: 2px 2px 0 0;
    border-collapse: separate;
    border-spacing: 0;
    background-color: transparent;
    color: #232121 !important
  }

  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1.5px;
    height: 1em;
    background-color: #fff;
    transform: translateY(-50%);
    transition: background-color .3s;
    content: '';
  }

  .ant-table-footer,
  .ant-table-thead>tr>th {
    background-color: @niq-blue-deep;
    color: #fff;
  }

  .ant-empty-normal {
    color: #fff;
  }

  .ant-empty-description {
    color: @niq-gray-60
  }

  .ant-select-selection-placeholder {
    flex: 1;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
  }

  a {
    color: var(--page-a);
  }

  a:hover {
    color: @niq-blue
  }

  // .ant-table-tbody>tr.ant-table-row:hover>td,
  // .ant-table-tbody>tr>td.ant-table-cell-row-hover {
  //   background: @niq-blue-deep;
  //   color: #fff !important;

  //   .ant-btn,
  //   .ant-btn-primary .ant-input-search-button {
  //     background-color: @niq-blue;
  //     border-color: @niq-blue;
  //     color: #fff;
  //   }
  // }

  .ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border-color: @niq-blue;
    color: @niq-blue !important;

    a {
      color: @niq-blue !important;
    }
  }

  .ant-divider-vertical {
    position: relative;
    top: -0.06em;
    display: inline-block;
    height: 0.9em;
    margin: 0 8px;
    vertical-align: middle;
    border-top: 0;
    border-left: 1px solid rgb(247 247 247);
  }

  .ant-pagination-item-ellipsis {
    color: #f5f5f5 !important;
  }

  .ant-pagination-item-link-icon {
    color: @niq-blue !important;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 13px;
    background-color: #7e7e7e;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #444343;
  }

  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 10px;
  }

  .nz-table-hide-scrollbar {
    scrollbar-color: #fafafa #fafafa;
    overflow: hidden !important;
  }

  .ant-pagination-next button,
  .ant-pagination-prev button {
    color: #f5f5f5 !important;
  }

  .ant-table.ant-table-small .ant-table-expanded-row-fixed {
    width: 100% !important;
  }

  .ant-btn,
  .ant-btn-primary .ant-input-search-button {
    background-color: @niq-blue-deep;
    border-color: @niq-blue-deep;
    color: #fff;
  }
}

.ant-row {
  display: flex;
  flex-flow: row wrap;
  align-items: center
}

.user-account {
  .ant-popover-inner-content {
    padding: 0px 0px;
    color: rgba(0, 0, 0, .85);
  }

  .ant-tooltip-inner {
    padding: 0px 0px;
  }
}

.ng2-pdf-viewer-container {
  overflow-x: hidden !important;
}